import React, { useState, useEffect } from "react";
import "./css/page.css";
import './mint.css'
import discordsvg from './img/discord-svgrepo-com.svg'
import twittersvg from './img/twitter-svgrepo-com.svg'
import emailsvg from "./img/email-svgrepo-com.svg"
import logoimg from "./logo512.png"
import trezzgif from "./trezzz.gif"
import Countdown from 'react-countdown';
import ProgressBar from "@ramonak/react-progress-bar";
import { DAppClient, TezosOperationType, NetworkType } from "@airgap/beacon-sdk";
import { green } from "@material-ui/core/colors";



const axios = require('axios');

const dAppClient = new DAppClient({ name: "Trezz mint page" });


const getWallet = (address) => {

}

function Home() {
  const [amountMinted, setAmountMinted] = useState(0)
  async function mint() {
    const result = await dAppClient.requestPermissions({
      network: {
        type: NetworkType.MAINNET,
      },
    });
    const activeAccount = await dAppClient.getActiveAccount();
    if (activeAccount) {
      console.log("Already connected:", activeAccount.address);
    } else {
      const permissions = await dAppClient.requestPermissions();
      console.log("New connection:", permissions.address);
    }

    let contract = "KT1JHJ63buCTpKnFZ8rw8JSWUUenNtq5kqqq"
    try {
      const result = await dAppClient.requestOperation({
        operationDetails: [
          {
            kind: TezosOperationType.TRANSACTION,
            amount: "10000000",
            destination: contract,
            parameters: {
              entrypoint: "collect",
              value: {
                "prim": "Unit"
              },
            },
          },
        ],
      });

      console.log(result);
    } catch (error) {
      console.log(
        `The contract call failed and the following error was returned:`,
        error?.data[1]?.with?.string
      );
      if (error?.data[1]?.with?.string == "MINT_NOT_ACTIVE") {
        alert("Mint not active")
      }
      if (error?.data[1]?.with?.string == "NOT_IN_ALLOWLIST") {
        alert("Not in allowlist")
      }
    }
  }


  const d = new Date("2022-04-22T22:00Z");
  const d2 = new Date("2022-04-22T24:00Z");

  async function getData() {
    const res = await axios.get('https://api.tzkt.io/v1/tokens/count',
      { params: { contract: "KT1TB24FHt4ArKCaN8J9WRPs1trhk5FJ4zBd" } }
    ).then(res => setAmountMinted(res.data));

  }

  useEffect(() => {
    getData()
  }, []);
  return (
    <div className="whole">

      <div className="head">
        <img className="headerimg" src={logoimg}></img>

        <p >ZeroStake</p>

        <p >An environmentally focused&nbsp;Tezos baker</p>

        <p className="butcont">
          <a href="https://twitter.com/zero_stake"><img src={twittersvg} className="mw" /></a>

          <a href="https://discord.gg/8GSGjGtEm2" ><img src={discordsvg} className="mw" /></a>

          <a href="mailto:admin@zerostake.xyz" ><img src={emailsvg} className="mw" /></a>

        </p>
        <a className="smol"
          href="https://tzkt.io/tz1N7fL6KvRzD5Umy7yeX5uBa5wFsC5veuN2/delegators">tz1N7fL6KvRzD5Umy7yeX5uBa5wFsC5veuN2</a>




        <br></br><br></br>
        <div className="imagesss">
        <ProgressBar
          bgColor="green"
          completed={amountMinted}
          maxCompleted={844}
        />
        </div>

        <a>Total minted {amountMinted}/844</a>
        <br></br><br></br>
        <a>Allowlist opens in <Countdown date={d} daysInHours={true} zeroPadTime={0} /></a>
        <br></br>
        <a>Public opens in <Countdown date={d2} daysInHours={true} /></a>
        <br></br><br></br>
        <img className="imagesss" src={trezzgif} />

        <div><button className="button" onClick={mint}>Mint one trezz - 10 Tez</button></div>


      </div>


      <br></br>
      <strong className="reeed">WARNING</strong> - We are nearing our delegation limit as a baker. If you are not already delegated with us keep this in mind, as current delegators will have priority for recieving rewards if we become overdelegated.


      <h5 >What are Trezz?</h5>

      <p>Trezz are Zerostakes baking fee reducing nft, with base art created by @qbnstudios and backgrounds made by various artists. Every single one automatically gives you a 25% fee reduction on the ZeroStake
        baker, with the chance for up to 100% reduction. To see how much you could save check out our calculator <a href="https://zerostake.xyz/#NFT">here</a>

        <br>
        </br> <br></br>
        Trezz will be generated based on the transaction hash of your purchase. The hashing algorithm will be available <a href="https://zerostake.xyz/hashverification/">here</a> for verification.

        <br>
        </br> <br></br>
        <a href={"https://tzkt.io/KT1TB24FHt4ArKCaN8J9WRPs1trhk5FJ4zBd"}>Token Contract</a>
        <br>
        </br> <br></br>
        <a href={"https://tzkt.io/KT1JHJ63buCTpKnFZ8rw8JSWUUenNtq5kqqq"}>Crowdsale Contract</a>
        <br>
        </br> <br></br>
        <strong>Note</strong> - only one Trezz discount (highest percentage) will be applied per wallet.
        <br>
        </br> <br></br>
      </p>

      {/* <ReactP5Wrapper sketch={sketch} /> */}

    </div>


  );
}

export default Home;